import { ApiConfig, axiosData, axiosNullableData, mapApi, SourceGetter } from '@imas/api';
import moment, { Moment } from 'moment';
import { TimeSheetSummaryResult } from '../time-sheet';
import { EmployeeBasics,CoachingSessions,  EmployeeRadAuditCompleteResult, EmployeeMilestoneDatesResult, EmployeeManagerSession, EmployeeRecognitionTracking, TimeOffAlertsResult, vEmployeeViolation, EmployeeViolationForm, EmployeeCertificationTracking } from './types';
import { EmployeeCertificationRadAudit } from './types/employees/EmployeeCertificationRadAudit';
import { TEmployeeCertificationRadAuditForm } from './types/employees/TEmployeeCertificationRadAudit';
import { objectToFormData } from '@imas/api';
import axios from 'axios';

//employees base url
const EMPLOYEES_URL = "/api/employees";

/*  */
export const GetEmployees = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeBasics[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployee = (getSource: SourceGetter) => {
    return async (employeeId: int): Promise<EmployeeBasics> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeManager = (getSource: SourceGetter) => {
    return async (employeeId: int): Promise<EmployeeBasics> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}/manager`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeLatestCoachingSession = (getSource: SourceGetter) => {
    return async (employeeId: int): Promise<EmployeeManagerSession | null> => {
        return axiosNullableData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}/latest-coaching-session`,
            cancelToken: getSource().token,
		});
    };
};

export const GetEmployeeNameByID = (getSource: SourceGetter) => {
    return async (employeeId: int): Promise<string> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}/name`,
            cancelToken: getSource().token,
		}); 
    };
};

export const GetAllEmployeeCoachingSessions = (getSource: SourceGetter) => {
    return async (employeeId: int): Promise<CoachingSessions[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}/all-coaching-session`,
            cancelToken: getSource().token,
		});
    };
};

export const GetCoachingSessions = (getSource: SourceGetter) => {
    return async (completed: boolean | null, fromDate?: Moment | null, endDate?: Moment | null, managerId?: int | null, employeeId?: int | null, sitenum?: int | null, depcode?: int | null): Promise<CoachingSessions[]> => {
        return axiosData({
            method: 'get',
            url: `${EMPLOYEES_URL}/coaching-session`,
            params: {
                completed: completed,
                fromDate: fromDate ? fromDate.toDate() : null,
                toDate: endDate ? endDate.toDate() : null,
                managerId: managerId,
                employeeId: employeeId,
                sitenum: sitenum,
                depcode: depcode
            },
            cancelToken: getSource().token,		
        });
    };
};


export const GetGenManagerEmails = (getSource: SourceGetter) => {
    return async (): Promise<string[]> => {
        return axiosData({
            method: 'get',
            url: `${EMPLOYEES_URL}/GenManagerEmails`,
            cancelToken: getSource().token,

        });
    };
};

/*  */
export const GetEmployeeClosestRecognition = (getSource: SourceGetter) => {
    return async (employeeId: int, date: Moment): Promise<EmployeeRecognitionTracking | null> => {
        return axiosNullableData({
			method: 'get',
            url: `${EMPLOYEES_URL}/${employeeId}/closest-recognition`,
			params : {
				date: date.toDate(),
			},
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeTimeSheetSummary = mapApi((employeeId: int, fromDate?: Moment | Date, toDate?: Moment | Date): ApiConfig<TimeSheetSummaryResult[]> => ({
	method: 'get',
	url: `${EMPLOYEES_URL}/${employeeId}/time-sheet-summary`,
	params : {
		fromDate: fromDate ? fromDate instanceof Date ? fromDate : fromDate.toDate() : moment().subtract(1, 'year').toDate(),
		toDate: toDate ? toDate instanceof Date ? toDate : toDate.toDate() : moment().toDate(),
	},
}));

/*  */
export const GetProjectManagers = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeBasics[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/project-managers`,
            cancelToken: getSource().token,
		});
    };
};

export const GetManagers = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeBasics[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/managers`,
            cancelToken: getSource().token,
		});
    };
};

/*  */
export const GetEmployeeMilestoneDates = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeMilestoneDatesResult[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/milestone-dates`,
            cancelToken: getSource().token,
		});
    };
};

/* returns alerts for an employee */
export const GetEmployeeAlerts = (getSource: SourceGetter) => {
    return async (employeeid: int): Promise<TimeOffAlertsResult[]> => {
        return axiosData({
			method: 'get',
            url: `${EMPLOYEES_URL}/alerts/${employeeid.toString()}`,
            cancelToken: getSource().token,
		});
    };
};

export const GetAllEmployeeViolations = (getSource: SourceGetter) => {
    return async (employeeid : int): Promise<vEmployeeViolation[]> => {
        return axiosData({

            method: 'get',
            url: `${EMPLOYEES_URL}/violations/${employeeid.toString()}`,
            cancelToken: getSource().token
        });
    };
};

export const GetEmployeeViolation = (getSource: SourceGetter) => {
    return async (violationid : int): Promise<vEmployeeViolation> => {
        return axiosData({

            method: 'get',
            url: `${EMPLOYEES_URL}/violation/${violationid.toString()}`,
            cancelToken: getSource().token
        });
    };
};

export const SignViolation = (getSource: SourceGetter) => {
    return async(data: EmployeeViolationForm) => {
        return axiosData({
            method: 'put',
            url: `${EMPLOYEES_URL}/signViolation`,
            data,
            cancelToken: getSource().token,

        });
    };
};
export const getAllEmployeeCert = (getSource: SourceGetter) => {
    return async (): Promise<EmployeeCertificationTracking[]> => {
        return axiosData({
            method: 'get',
            url: `${EMPLOYEES_URL}/certification`,
            cancelToken: getSource().token
        });
    };
};

export const getEmployeeCert = (getSource: SourceGetter) => {
    return async (id: int): Promise<EmployeeCertificationTracking[]> => {
        return axiosData({
            method: 'get',
            url: `${EMPLOYEES_URL}/certification/${id}`,
            cancelToken: getSource().token
        });
    };
};

export const GetCertificationRadAudit = (getSource: SourceGetter) => {
    return async (certradauditID: int): Promise<EmployeeCertificationRadAudit> => {
        return axiosData({
            method: 'get',
            url: `${EMPLOYEES_URL}/certification/radaudit/${certradauditID}`,
            cancelToken: getSource().token
        });
    };
};

export const EditCertificationRadAudit = (getSource: SourceGetter) => {
    return async (data: TEmployeeCertificationRadAuditForm, certradauditID: int ): Promise<EmployeeCertificationRadAudit> => {
        return axiosData({
            method: 'put',
            url: `${EMPLOYEES_URL}/certification/radaudit/${certradauditID}`,
            data: objectToFormData(data),
            cancelToken: getSource().token
        });
    };
};

export const CreateCertificationRadAudit = (getSource: SourceGetter) => {
    return async (data: TEmployeeCertificationRadAuditForm, employeeID: int, trackingID:int ): Promise<EmployeeCertificationRadAudit> => {
        return axiosData({
            method: 'post',
            url: `${EMPLOYEES_URL}/certification/radaudit/${employeeID}/${trackingID}`,
            data: objectToFormData(data),
            cancelToken: getSource().token
        });
    };
};

export const EmailCertificationRadAudit = (getSource: SourceGetter) => {
    return async ( certradauditID:int ): Promise<int> => {
        return axiosData({
            method: 'post',
            url: `${EMPLOYEES_URL}/certification/radaudit/email/${certradauditID}`,
            cancelToken: getSource().token
        });
    };
};

export const AuditSign = (getSource: SourceGetter) => {
    return  (Id: int, employeeId: int, file: File, ): Promise<void> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<void>((resolve, reject) => {
            axios({
                method: 'put',
                url: `${EMPLOYEES_URL}/audit-sign`,
				data: objectToFormData({ Id:Id, employeeId: employeeId, file: file }),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

export const RSOSign = (getSource: SourceGetter) => {
    return  (Id: int, employeeId: int, file: File, ): Promise<void> => {
        //create request source 
        const source = getSource();

        //make API call and return a promise which resolves into the result
        return new Promise<void>((resolve, reject) => {
            axios({
                method: 'put',
                url: `${EMPLOYEES_URL}/rso-sign`,
				data: objectToFormData({ Id:Id, employeeId: employeeId, file: file }),
                cancelToken: source.token
            }).then(response => {
                //resolve promise
                resolve(response.data);
            }).catch(e => {
				reject(e);
			});
        });
    };
};

export const CompleteRadAudit = (getSource: SourceGetter) => {
    return async ( certradauditID:int ): Promise<EmployeeRadAuditCompleteResult> => {
        return axiosData({
            method: 'put',
            url: `${EMPLOYEES_URL}/complete/radaudit/${certradauditID}`,
            cancelToken: getSource().token
        });
    };
};
