import { makeDeviceStyles } from '@imas/styles';

export const desktopCertificationsStyles = makeDeviceStyles()((theme) => ({

    pastDue: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white!important',
    },
    lastAlert: {
        backgroundColor: 'orange!important',
        color: 'black!important',
    },
    secondAlert: {
        backgroundColor: 'yellow!important',
        color: 'black!important',
    },
    firstAlert: {
        backgroundColor: theme.palette.success.light,
        color: 'black!important',
    },
    
    pastDueLegend : {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    lastAlertLegend: {
        backgroundColor: 'orange',
        color: 'black',
    },
    secondAlertLegend : {
        backgroundColor: 'yellow',
        color: 'black',
    },
    firstAlertLegend: {
        backgroundColor: theme.palette.success.light,
        color: 'black',
    }
}));