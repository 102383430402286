import { Accordion, AccordionDetails, AccordionSummary, List, Typography, ListItem, ListItemText } from '@mui/material';
import { Alignment } from '@imas/components/layout';
import { GetSORadAudits } from '@imas/api/proposal';
import { useLoadApi } from '@imas/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useUserId } from '@imas/utils/user';
import { GetEmployee } from '@imas/api/employee';
import { desktopCertificationsStyles } from 'src/pages/User/Certifications/DesktopCertifications/DesktopCertificationsStyles';
import { openARRACertification } from 'src/pages/Managers/Certifications/ARRACertification/ARRACertification';
import { useAllSORadAudits } from '@imas/api/data-hooks';
import { useEffect, useState } from 'react';
import { GetSites } from '@imas/api/system';
export interface RadAuditsListProps {
    uniqueID: number; //the unique ID to pull the audit
}

export const RadAuditsList = ({ uniqueID }: RadAuditsListProps) => {
    //establishing connection to the audits
    const { data: audits } = useLoadApi(GetSORadAudits, [uniqueID ?? 0], [uniqueID]);
    const { classes, cx } = desktopCertificationsStyles();
    //Grabbing employeeBasics data that contains arracert for permissions
    const userId = useUserId();
    const { data: employee} = useLoadApi(GetEmployee, [userId], [userId]);

    const {data: sites, call: refreshSites} = useLoadApi(GetSites, []);

    const [isRSO, setIsRSO] = useState(false);

    useEffect(() => {
        if(sites)
        {
            if( sites[0].siteradsafetyofficerID === userId || sites[0].siteradsafetyofficeralternateID === userId)
                { 
                    setIsRSO(true);
                }
        }
       
    }, [sites]);
    
    return (
        <>
        {(audits !== undefined && audits.length !== 0) ?         <Typography fontWeight={'bold'}>{'Audit Due Date(s):'}</Typography>
: null}
        
        <List>
            {audits?.map((data, index) => (
                <Alignment flex row key={index} sx={{maxWidth: '520px'}}>
                    {/* User needs arracert of 4 to be able to access */}
                    {employee?.arracert !== 4 ? (
                        <ListItem style={{ width: "100%", paddingTop: "2px", paddingBottom: "2px" }}>
                            <ListItemText primary={data.employeeName} secondary={(data.radAuditStatus === "Pending Reviewer Signature" && isRSO === false) ? "Completed" : data.radAuditStatus} />
                            <Typography>{data.certificationName}</Typography>
                            
                            {/* Date and Styling */}
                    <Typography
                        sx={{margin: '10px 0 10px 10px', padding: '3px 3px 3px 3px' }}
                        className={
                            data.nextDueStatus === 4
                                ? ""
                                : data.nextDueStatus === 3
                                ? classes.firstAlert
                                : data.nextDueStatus === 2
                                ? classes.secondAlert
                                : data.nextDueStatus === 1
                                ? classes.lastAlert
                                : data.nextDueStatus === 0
                                ? classes.pastDue
                                : ""
                        }
                    >
                        {moment(data.expirationdate).format('M/DD/YYYY')}
                    </Typography>
                        </ListItem>
                    ) : (
                        <ListItem
                            button
                            onClick={() => {

                                if(data.certradauditID === null)
                                {
                                    //if this is the case then create a new one using the employeeID
                                    openARRACertification({employeeID: data.employeeID, trackingID: data.trackingID, servloc: data.servloc, arracert: data.arracert});

                                }
                                else{
                                    //otherwise open the existing record for signage.
                                    openARRACertification({certradauditID: data.certradauditID});
                                }

                            }} 
                            style={{ width: "-webkit-fill-available", paddingTop: "2px", paddingBottom: "2px" }}
                        >
                            <ListItemText primary={data.employeeName} secondary={data.radAuditStatus} />
                            <Typography>{data.certificationName}</Typography>

                            {/* Date and Styling */}
                    <Typography
                        sx={{margin: '10px 0 10px 10px', padding: '3px 3px 3px 3px' }}
                        className={
                            data.nextDueStatus === 4
                                ? ""
                                : data.nextDueStatus === 3
                                ? classes.firstAlert
                                : data.nextDueStatus === 2
                                ? classes.secondAlert
                                : data.nextDueStatus === 1
                                ? classes.lastAlert
                                : data.nextDueStatus === 0
                                ? classes.pastDue
                                : ""
                        }
                    >
                        {moment(data.expirationdate).format('M/DD/YYYY')}
                    </Typography>
                        </ListItem>
                    )}
                    
                </Alignment>
            ))}
        </List>
        </>
    );
};
