import { Alignment } from "@imas/components/layout";
import { openEditorWindow,useEditorState } from "@imas/utils/editor";
import { Paper, Button, Dialog, Box, DialogTitle, DialogActions, Typography, DialogContent } from "@mui/material";
import { EmployeeCertificationRadAudit } from "@imas/api/employee/types/employees/EmployeeCertificationRadAudit";
import { TEmployeeCertificationRadAuditForm } from "@imas/api/employee/types/employees/TEmployeeCertificationRadAudit";
import { MuiFormSubmit, MuiFormSubmitProps, useMuiFormRef } from '@imas/mui-form';
import { ARRACertificationForm } from "./ARRACertificationForm.tsx/ARRACertificationForm";
import { useCallback, useEffect, useMemo } from "react";
import { CreateCertificationRadAudit, GetEmployee, GetCertificationRadAudit, EditCertificationRadAudit, EmailCertificationRadAudit, RSOSign, AuditSign, CompleteRadAudit } from "@imas/api/employee";
import { useApi, useLoadApi } from "@imas/api";
import { isPopup } from '@imas/utils/misc';
import { ExitToApp, Save as SaveIcon } from '@mui/icons-material';
import SignatureCanvas from 'react-signature-canvas';
import { useState } from "react";
import { GetSites } from "@imas/api/system";
import { useUserId } from '@imas/utils/user';
import { Draw } from 'mdi-material-ui';
import { Warning as WarningIcon, CheckCircle as CheckIcon } from "@mui/icons-material";
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import moment from "moment";
import { useNavigate, Navigate } from 'react-router-dom';



type ARRACertificationEditorParams = { employeeID? : string, trackingID? : string, servloc?: string, arracert?: string};

//so far, there are three instances where a user can access a rad audit.
//first is if the audit already exists and needs no params.
//second is if the audit does not exist and is linked to a service order with a location and trackingId
//third is if the audit is accessed from the manager view and there is no location

type ARRACertificationEditorOptions = { 
    certradauditID : int;
    employeeID?: undefined;
    trackingID?: undefined;
    servloc?: undefined;
    arracert?: undefined;
} | {
    certradauditID? : undefined;
    employeeID: int;
    trackingID: int;
    servloc: string;
    arracert: int;
    
} | {
  certradauditID? : undefined;
    employeeID: int;
    trackingID: int;
    servloc?: string;
    arracert: int;
}


//function to open the window. imports the params
export const openARRACertification = ({ certradauditID, employeeID, trackingID, servloc, arracert } : ARRACertificationEditorOptions) : Window | null => {
    return openEditorWindow<ARRACertificationEditorParams>(
        IMAS.PAGES.MANAGE.ARRA_CERTIFICATION(),
        {id : certradauditID ?? null, employeeID: employeeID?.toString(), trackingID: trackingID?.toString(), servloc: servloc, arracert: arracert?.toString() },
        { height: 850, width: 480, name: "ARRA Certification", left: (window.innerWidth - 435)/2 }


    );
};



export const ARRACertification = () => {

  //userID is used for getting specific data aswell as submitting data
    const userId = useUserId();

    //sigCanvas definition, for drawing teh signature
    let sigCanvas: SignatureCanvas | null;

    //function to clear the signature
    const handleSignatureClear = () => {
		if (sigCanvas !== null) {
			sigCanvas.clear();
		}
	};

  //showsnackbar
	const showSnackbar = useAutomaticSnackbar();

  //boolean usestates for opening signing dialogs and to tell if the form should be disabled
    const [DoneBySignatureOpen, setDoneBySignatureOpen] = useState(false);
    const [reviewedBySignatureOpen, setReviewedBySignatureOpen] = useState(false);
    const [completed, setCompleted] = useState(false);
   
    //for deciding RSO and ARSO
    const {data: sites, call: refreshSites} = useLoadApi(GetSites, []);
    const [isRSO, setIsRSO] = useState(false);

    useEffect(() => {
        if(sites)
        {
            if( sites[0].siteradsafetyofficerID === userId || sites[0].siteradsafetyofficeralternateID === userId)
                { 
                    setIsRSO(true);
                }
        }
       
    }, [sites]);
    //Canvas functions

    //function for handling the dailog close
    const handleDoneBySignatureClose = () => {
      setDoneBySignatureOpen(false);
    };
   
    const handleReviewBySignatureClose = () => {
      setReviewedBySignatureOpen(false);
    };

   
  //handles the signature submission
    const handleDoneBySignatureConfirm = () => 
        {
          //aslong as the canvas exists, set the signature
          if (sigCanvas!== null) 
            {
              setDoneBySignatureDataUrl(sigCanvas.toDataURL());
            }

            //set the value of donebyID. this was origianlly not part of the form, but the submission of the form needs it for the email.
          formRef?.setValue('certradauditdonebyID', userId); 
          //close the dialog box
          handleDoneBySignatureClose();
        };
        //handles the other signature submission
        const handleReviewedBySignatureConfirm = () => {
          if (sigCanvas !== null) 
            {
                  setReveiwedBySignatureDataUrl(sigCanvas.toDataURL());

          }

          formRef?.setValue('certradauditreviewedbyID', userId);
          //close the dialgo box
          handleReviewBySignatureClose();
        };


        //state from the url

    const [state, setState] = useEditorState<ARRACertificationEditorParams>({ url:  IMAS.PAGES.MANAGE.ARRA_CERTIFICATION(), });

    //parsing the variables from the editor state
    const employeeID = useMemo(() => {
        const stringparam = state.employeeID;
        if(stringparam === undefined) return null;
        return parseInt(stringparam);
    }, [state]);

    const trackingID = useMemo(() => {
        const stringparam = state.trackingID;
        if(stringparam === undefined) return null;
        return parseInt(stringparam);
    }, [state]);

    const servloc = useMemo(() => {
        return state.servloc;
    }, [state]);

    const arracert = useMemo(() => {
        const stringparam = state.arracert;
        if(stringparam === undefined) return null;
        return parseInt(stringparam);    
    }, [state]);

    const certradauditID = useMemo(() => {
        return state.id;

    }, [state]);

    //employee is used to get the name of the employee being audited
    const {data: employee} = useLoadApi(GetEmployee, [employeeID ?? -1], [employeeID], {disabled: employeeID === null});

    //the audit data for edit
    const {data: audit, clear: clearAudit} = useLoadApi(GetCertificationRadAudit, [certradauditID ?? -1], [certradauditID], {disabled: certradauditID === null});

    //editEmployee is used to get the na,e of the employee being audited when the audit exists
    const {data: editEmployee} =  useLoadApi(GetEmployee, [audit?.employeeID ?? -1], [audit?.employeeID], {disabled: audit === undefined});

    //auditor employee name
    const {data: auditor} =  useLoadApi(GetEmployee, [audit?.certradauditdonebyID ?? -1], [audit?.certradauditdonebyID], {disabled: audit === undefined || audit?.certradauditdonebyID === null});
    //rso employee name
    const {data: rso} =  useLoadApi(GetEmployee, [audit?.certradauditreviewedbyID ?? -1], [audit?.certradauditreviewedbyID], {disabled: audit === undefined || audit?.certradauditreviewedbyID === null});


    //logic to get the employee name being the varialbe reguardless of if the name comes from the url params or 
    const employeeName = useMemo(() => {
        if(employee !== undefined)  return employee?.name;
        else if(editEmployee !== undefined) return editEmployee?.name;
        else{
           return null;
        }

    }, [employee, editEmployee]);


    //formreference
    const [formRef, onFormRef] = useMuiFormRef<TEmployeeCertificationRadAuditForm, EmployeeCertificationRadAudit>();

    //The two data urls, being set when signing
    const [DoneBySignatureDataUrl, setDoneBySignatureDataUrl] = useState<string | null>();
    const [reveiwedBySignatureDataUrl, setReveiwedBySignatureDataUrl] = useState<string | null>();
    


    useEffect(() => {

      //this is a useEffect for whenever the audit gets loaded.
      //this alows the user to see the saved file in the same spot as whe
        if(audit?.doneByJsonFile?.content !== null && audit?.doneByJsonFile?.content !== undefined)
        {
            setDoneBySignatureDataUrl("data:image/png;base64," + audit?.doneByJsonFile?.content);
        }
        if(audit?.reviewedByJsonFile?.content !== null && audit?.reviewedByJsonFile?.content !== undefined)
        {
            setReveiwedBySignatureDataUrl("data:image/png;base64," + audit?.reviewedByJsonFile?.content);

        }
        if(audit?.certhistoryID !== null && audit?.certhistoryID !== undefined)
        {
            setCompleted(true);
        }
    },[audit]);

    useEffect(() => {

        clearAudit();

        console.log(state.id);
        if(state.id === null)
        {
            formRef?.reset({
                certradauditlocation: servloc,
                certradauditarracert: arracert
            });
        }

    }, [state, servloc,arracert]);

    useEffect(() => {
            formRef?.reset({
                ...audit,
            });      
    }, [audit, servloc, arracert]);

    const isDisabled = useMemo(() => {
        console.log(DoneBySignatureDataUrl);
        if(DoneBySignatureDataUrl !== null && DoneBySignatureDataUrl !== undefined)
        {
            return false;
        }else{
            return true;
        }

    }, [DoneBySignatureDataUrl]);
     
   

    const createCertificationRadAudit = useApi(CreateCertificationRadAudit);
    const editCertificationRadAudit = useApi(EditCertificationRadAudit);
    const emailCertificationRadAudit = useApi(EmailCertificationRadAudit);
    const signAudit = useApi(AuditSign);
    const signRSO = useApi(RSOSign);
    const completeRadAudit = useApi(CompleteRadAudit);

    const onSubmit = useCallback<MuiFormSubmitProps<TEmployeeCertificationRadAuditForm, EmployeeCertificationRadAudit>["onSubmit"]>(async(data) => {
        if(certradauditID !== null)
        {
            return await editCertificationRadAudit(data, certradauditID);
        }
        else{
            return await createCertificationRadAudit(data, employeeID ?? -1, trackingID ?? -1);

        }
    }, [employeeID, trackingID,certradauditID ]);


    return (
        <>
        
        
        <Paper>
            
            <Alignment flex column overflowHidden sx={{ margin: '10px', maxWidth: window.innerWidth < 500 ? `${window.innerWidth}px` : '1000px' }}>
			<Alignment column sx={{height: '-webkit-fill-available'}}> 
                <ARRACertificationForm
                employeeName={employeeName ?? ""}
                disabled={completed}
                servloc={servloc ?? null}
                arracert={arracert ?? null}
                ref={onFormRef}
                />


<Alignment row sx={{justifyContent: 'space-between', marginTop: '10px'}}>
             
            </Alignment>
            <Alignment sx={{justifyContent: 'space-between', marginTop: '10px'}}>
            {DoneBySignatureDataUrl !== null ?
            <Alignment row>
              <Alignment column> 
              <Button sx={{marginBottom: '10px', marginTop: '10px', width: '100px', height: '35px', marginRight: '10px'}} disabled={completed} startIcon={<Draw/>} onClick={() => setDoneBySignatureOpen(true)}>Auditor</Button>
              {auditor ? <Typography>{auditor.name}</Typography> : null}
              {audit ? <Typography>{audit.certradauditdonebysignaturedatetime ? moment(audit.certradauditdonebysignaturedatetime).format("MM/DD/YYYY") : null}</Typography> : null}
              </Alignment>
              <Box component={'img'} src={DoneBySignatureDataUrl} sx={{width: window.innerWidth <= 360 ? '225px' : '250px', height: '120px', minHeight: '120px', backgroundColor: 'white'}} />

            </Alignment>
						:  null}

            {reveiwedBySignatureDataUrl !== null ? 
                        <Alignment row sx={{marginTop: '10px'}}>
                                        <Alignment column> 

 <Button sx={{marginBottom: '10px', marginTop: '10px', width: '100px', height: '35px', marginRight: '10px'}} startIcon={<Draw/>} disabled={
  sites
    ? !( sites[0].siteradsafetyofficerID === userId || sites[0].siteradsafetyofficeralternateID === userId ) || completed
    : true
}
 onClick={() => setReviewedBySignatureOpen(true)}>RSO</Button>
  {rso ? <Typography>{rso.name}</Typography> : null}
  {audit ? <Typography>{audit.certradauditreviewedbysignaturedatetime ? moment(audit.certradauditreviewedbysignaturedatetime).format("MM/DD/YYYY") : null}</Typography> : null}
              </Alignment>

							<Box component={'img'} src={reveiwedBySignatureDataUrl} sx={{width: window.innerWidth <= 360 ? '225px' : '250px', height: '120px', minHeight: '120px', backgroundColor: 'white'}} />
              </Alignment>

						:  null}

            </Alignment>
                

            </Alignment>
            <Alignment rowReverse sx={{ marginTop: '10px', "& > *:not(:last-child)": { marginLeft: '10px' } }}>

              

				{/* Save Button */}
				<MuiFormSubmit
					formRef={formRef}
                    disabled={isDisabled}
					onSubmit={onSubmit}
					onSuccessfulSubmit={async (result) => {
            const close = showSnackbar('Submitting Radiation Audit...', { variant: 'success', persist: true });

                        if(DoneBySignatureDataUrl !== null && DoneBySignatureDataUrl !== undefined)
                        {
                            let dataUrl = 'error';
                            dataUrl = DoneBySignatureDataUrl;
                            let arr = dataUrl.split(','),
                            bstr = atob(arr[1]), 
                            n = bstr.length, 
                            u8arr = new Uint8Array(n);
                            while(n--){
                                   u8arr[n] = bstr.charCodeAt(n);
                               }			
                    
                               let sigfile = new File([u8arr], 'DoneBySignature');

                               await signAudit(result.id, userId, sigfile);
                        }

                        if(reveiwedBySignatureDataUrl !== null && reveiwedBySignatureDataUrl !== undefined)
                        {
                            let dataUrl = 'error';
                            dataUrl = reveiwedBySignatureDataUrl;
                            let arr = dataUrl.split(','),
                            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                            while(n--){
                                   u8arr[n] = bstr.charCodeAt(n);
                               }			
                    
                               let sigfile = new File([u8arr], 'ReviewedBySignature');

                               await signRSO(result.id,userId, sigfile);
                        }
                        close();

                        if(result.certhistoryID === null && result.certradauditreviewedbyID !== null)
                        {
                            var note = await completeRadAudit(result.id);
                            showSnackbar(`${note.note}`, {variant: note.result ? 'success' : 'info'}, true);

                            setCompleted(note.result);
                        }
                        if(isRSO === false)
                        {
                          showSnackbar("Audit has been submitted for review by the RSO.", {variant: 'success'}, true);

                        }

                        if(certradauditID === null)
                        {
                          window.close();
                        }
					}}
					startIcon={<SaveIcon/>}	
					size={'small'}
				>{"Save"}</MuiFormSubmit>

				{/* Exit Button */}
				{isPopup() ? (
					<Button
						variant = "contained"
						color = "secondary"
						onClick={() => {window.close(); }}
					>{"Exit"}</Button>
				): null }
                </Alignment>
            </Alignment>
        </Paper>
        <Dialog 
  open={DoneBySignatureOpen} 
  onClose={handleDoneBySignatureClose} 
  fullScreen={true}
>
  {/* Title */}
  <DialogTitle sx={{ textAlign: 'center', paddingBottom: 1, marginTop: '220px' }}>
    <Typography variant="h5" fontWeight={'bold'}>Sign Below as Auditor</Typography>
  </DialogTitle>

  {/* Signature Box */}
  <DialogContent sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 1.5, // Reduced gap for closer alignment
    padding: 1,
  }}>
    <Box sx={{
      width: '100%',
      maxWidth: '400px',
      height: (window.innerWidth >= 360) ? '500px' : '230px', // Slightly reduced height
      minHeight: (window.innerWidth >= 360) ? '500px' : '230px',  
      border: 2,
      borderColor: 'black',
      borderRadius: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      marginBottom: 1,
    }}>
      <SignatureCanvas
					 	canvasProps={{width:550, height:250}}
					 	ref={(ref) => {{sigCanvas = ref;}}}
					 	backgroundColor={'#ffffff'}
						minWidth={2}
                        
                    
					 >
	</SignatureCanvas>
    </Box>
  </DialogContent>

  {/* Buttons */}
  <DialogActions sx={{
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 1,
    marginBottom: 1,
  }}>
    <Alignment row sx={{marginBottom: '250px', alignItems: 'center'}}>
    <Button 
      onClick={handleSignatureClear} 
      color="secondary" 
      sx={{ flex: 1, marginRight: '10px' }}
    >
      Clear
    </Button>
    <Button 
      onClick={handleDoneBySignatureClose} 
      color="secondary" 
      sx={{ flex: 1, marginRight: '10px' }}
    >
      Cancel
    </Button>
    <Button 
      onClick={handleDoneBySignatureConfirm} 
      variant="contained"
      sx={{ flex: 1 }}
    >
      Ok
    </Button>
    </Alignment>
    
  </DialogActions>
</Dialog>
<Dialog 
  open={reviewedBySignatureOpen} 
  onClose={handleReviewBySignatureClose} 
  fullScreen={true}
>
  {/* Title */}
  <DialogTitle sx={{ textAlign: 'center', paddingBottom: 1, marginTop: '220px' }}>
    <Typography variant="h5" fontWeight={'bold'}>Sign Below as RSO</Typography>
  </DialogTitle>

  {/* Signature Box */}
  <DialogContent sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 1.5, // Reduced gap for closer alignment
    padding: 1,
  }}>
    <Box sx={{
      width: '100%',
      maxWidth: '400px',
      height: (window.innerWidth >= 360) ? '500px' : '230px', // Slightly reduced height
      minHeight: (window.innerWidth >= 360) ? '500px' : '230px',  
      border: 2,
      borderColor: 'black',
      borderRadius: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      marginBottom: '100px',
      
    }}>
      <SignatureCanvas
					 	canvasProps={{width:550, height:250}}
					 	ref={(ref) => {{sigCanvas = ref;}}}
					 	backgroundColor={'#ffffff'}
						minWidth={2}
                        
                    
					 >
	</SignatureCanvas>
    </Box>
  </DialogContent>

  {/* Buttons */}
  <DialogActions sx={{
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 1,
    marginBottom: 1,
  }}>
    <Alignment row sx={{marginBottom: '250px'}}>
    <Button 
      onClick={handleSignatureClear} 
      color="secondary" 
      sx={{ flex: 1, marginRight: '10px' }}
    >
      Clear
    </Button>
    <Button 
      onClick={handleReviewBySignatureClose} 
      color="secondary" 
      sx={{ flex: 1, marginRight: '10px' }}
    >
      Cancel
    </Button>
    <Button 
      onClick={handleReviewedBySignatureConfirm} 
      variant="contained"
      sx={{ flex: 1 }}
    >
      Ok
    </Button>
    </Alignment>
    
  </DialogActions>
</Dialog>
                {/*  */}
                
        </>
    );

};