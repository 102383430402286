import { EmployeeCertificationRadAudit } from "@imas/api/employee/types/employees/EmployeeCertificationRadAudit";
import { FormAutocomplete, FormDatePicker, FormNumberSelect, FormNumberTextField, FormTextField, FormYesNo, MuiForm, MuiFormPartial, MuiFormRef, useMuiForm, useMuiWatch, FormYesNoSwitch } from "@imas/mui-form";
import { Editable, FormRefSubmitHandler } from '@imas/mui-form/types';
import React, { useState, useMemo, useEffect, useCallback, ChangeEvent, useRef } from 'react';
import { Box, Tab, Tabs, TextField, Typography, Divider, Dialog, DialogContent, DialogActions, Button, Fab, DialogTitle } from '@mui/material';
import { Alignment, Spacer } from "@imas/components/layout";
import { useLoadApi } from "@imas/api";
import { GetEmployee } from "@imas/api/employee";
import { FormTimePicker } from "@imas/mui-form/FormTimePicker";
import { resizeImage } from '@imas/utils/files';
import { TEmployeeCertificationRadAuditForm } from "@imas/api/employee/types/employees/TEmployeeCertificationRadAudit";
import { Draw } from 'mdi-material-ui';
import { useUserId } from '@imas/utils/user';
import { useDeviceInfo } from "@imas/styles";
import { Warning as WarningIcon, CheckCircle as CheckIcon } from "@mui/icons-material";
import { GetSites } from "@imas/api/system";
import useMediaQuery from '@mui/material/useMediaQuery';
export const ARRACertificationFormDefaultValues: Editable<TEmployeeCertificationRadAuditForm> = {
    certradauditdate: null,
    certradauditarracert: null,
    certradauditdevicemodelno: null,
    certradauditlocation: null,
    certradauditfilmbadge: null,
    certradauditcameraserialno: null,
    certradaudittime: null,
    certradauditsourcetype: null,
    certradauditsourcestrength: null,
    certradauditsourceserialno: null,
    certradauditsurveymetermodel: null,
    certradauditsurveymeterserialno: null,
    certradauditsurveymetercalduedate: null,
    certradauditdosimetermodel: null,
    certradauditdosimeterserialno: null,
    certradauditdosimetercalduedate: null,
    certradauditalarmratemetermodel: null,
    certradauditalarmratemeterserialno: null,
    certradauditalarmratemetercalduedate: null,
    certradauditPPEcheck: false,
    certradauditPPEothercheck: false,
    certradauditrestrictedsignagecheck: false,
    certradauditrestrictedcontrolcheck: false,
    certradaudithighradsignagecheck: false,
    certradauditmetercheck: false,
    certradauditlogcheck: false,
    certradauditknowledgecheck: false,
    certradauditequipmentcheck: false,
    certradauditreadingcheck: false,
    certradauditstoragecheck: false,
    certradauditstoragesignagecheck: false,
    certradauditprocedurecheck: false,
    certradauditusagecheck: false,
    certradauditrestrictedvisualcheck: false,
    certradauditnoncompliancecheck: false,
    certradauditsecuritycontrolcheck: false,
    certradauditresult: null,
    certradauditnotes: null,
    certradauditdonebyID: -1,
    certradauditreviewedbyID: null,
};

interface ARRACertificationFormProps extends MuiFormPartial {
    employeeName: string;
    disabled: boolean;
    employeeID?: int;
    servloc?: string | null;
    arracert?: int | null;
    onSubmit?: FormRefSubmitHandler<TEmployeeCertificationRadAuditForm,EmployeeCertificationRadAudit>;
}

export const ARRACertificationForm = React.memo(React.forwardRef<MuiFormRef<TEmployeeCertificationRadAuditForm>, ARRACertificationFormProps>(({ onSubmit, employeeName, disabled, servloc, arracert, ...props}, ref) => {

    const levelOptions = [{label: "Assistant Radiographer", value: 2}, {label: "Radiographer", value: 3}, {label: "Radiographer/Auditor", value: 4}];
    const resultOptions = [{label: "Satisfactory", value: 1}, {label: "Unsatisfactory", value: 6}];
    const deviceModelOptions = ["IR-100",  "880 Sigma"];
    const radioisotopeOptions = ["Iridium-192", "Selenium-75"];
    const { control, muiFormProps, setValue, getValues} = useMuiForm({
        defaultValues: ARRACertificationFormDefaultValues,
        validator: ({

          certradauditdate, 
          certradauditarracert,
          certradauditdevicemodelno,
          certradauditlocation,
          certradauditfilmbadge,
          certradauditcameraserialno,
          certradauditsourcetype,
          certradauditsourcestrength,
          certradauditsourceserialno,
          certradauditsurveymetermodel,
          certradauditsurveymeterserialno,
          certradauditsurveymetercalduedate,
          certradauditdosimetermodel,
          certradauditdosimeterserialno,
          certradauditdosimetercalduedate,
          certradauditalarmratemetermodel,
          certradauditalarmratemeterserialno, 
          certradauditalarmratemetercalduedate,
          certradauditPPEcheck, 
          certradauditPPEothercheck, 
          certradauditrestrictedsignagecheck, 
          certradauditrestrictedcontrolcheck, 
          certradaudithighradsignagecheck, 
          certradauditmetercheck, 
          certradauditlogcheck, 
          certradauditknowledgecheck, 
          certradauditequipmentcheck, 
          certradauditreadingcheck, 
          certradauditstoragecheck, 
          certradauditstoragesignagecheck, 
          certradauditprocedurecheck, 
          certradauditusagecheck, 
          certradauditrestrictedvisualcheck, 
          certradauditnoncompliancecheck, 
          certradauditsecuritycontrolcheck, 
          certradauditresult, 
          certradauditnotes,
          certradaudittime,
          certradauditdonebyID,
          certradauditreviewedbyID,
             ...fields  }, error) => {
              if(servloc !== null && servloc !== undefined)
              {
                setValue('certradauditlocation', servloc);
              }

              if(arracert !== null && arracert !== undefined)
                {
                  setValue('certradauditarracert', arracert);
                }

                if(certradauditdate === null) error('certradauditdate', 'required');
                if(certradauditarracert === null) error('certradauditarracert', 'required');
                if(certradaudittime === null) error('certradaudittime', 'required');
                if(certradauditdevicemodelno === null) error('certradauditdevicemodelno', 'required');
                if(certradauditlocation === null) error('certradauditlocation', 'required');
                if(certradauditfilmbadge === null) error('certradauditfilmbadge', 'required');
                if(certradauditcameraserialno === null) error('certradauditcameraserialno', 'required');
                if(certradauditsourcetype === null) error('certradauditsourcetype', 'required');
                if(certradauditsourcestrength === null) error('certradauditsourcestrength', 'required');
                if(certradauditsourceserialno === null) error('certradauditsourceserialno', 'required');
                if(certradauditsurveymetermodel === null) error('certradauditsurveymetermodel', 'required');
                if(certradauditsurveymeterserialno === null) error('certradauditsurveymeterserialno', 'required');
                if(certradauditsurveymetercalduedate === null) error('certradauditsurveymetercalduedate', 'required');
                if(certradauditdosimetermodel === null) error('certradauditdosimetermodel', 'required');
                if(certradauditdosimeterserialno === null) error('certradauditdosimeterserialno', 'required');
                if(certradauditdosimetercalduedate === null) error('certradauditdosimetercalduedate', 'required');
                if(certradauditalarmratemetermodel === null) error('certradauditalarmratemetermodel', 'required');
                if(certradauditalarmratemeterserialno === null) error('certradauditalarmratemeterserialno', 'required');
                if(certradauditalarmratemetercalduedate === null) error('certradauditalarmratemetercalduedate', 'required');
                if(certradauditPPEcheck === null) error('certradauditPPEcheck', 'required');
                if(certradauditPPEothercheck === null) error('certradauditPPEothercheck', 'required');
                if(certradauditrestrictedsignagecheck === null) error('certradauditrestrictedsignagecheck', 'required');
                if(certradauditrestrictedcontrolcheck === null) error('certradauditrestrictedcontrolcheck', 'required');
                if(certradaudithighradsignagecheck === null) error('certradaudithighradsignagecheck', 'required');
                if(certradauditmetercheck === null) error('certradauditmetercheck', 'required');
                if(certradauditequipmentcheck === null) error('certradauditequipmentcheck', 'required');
                if(certradauditreadingcheck === null) error('certradauditreadingcheck', 'required');
                if(certradauditstoragecheck === null) error('certradauditstoragecheck', 'required');
                if(certradauditstoragesignagecheck === null) error('certradauditstoragesignagecheck', 'required');
                if(certradauditprocedurecheck === null) error('certradauditprocedurecheck', 'required');
                if(certradauditusagecheck === null) error('certradauditusagecheck', 'required');
                if(certradauditrestrictedvisualcheck === null) error('certradauditrestrictedvisualcheck', 'required');
                if(certradauditsecuritycontrolcheck === null) error('certradauditsecuritycontrolcheck', 'required');
                if(certradauditresult === null) error('certradauditresult', 'required');


                if(certradauditdonebyID === null) error('certradauditdonebyID', 'required');

                if(certradauditdate === null) return;
                if(certradauditarracert === null) return;
                if(certradaudittime === null) return;
                if(certradauditlocation === null) return;
                if(certradauditfilmbadge === null) return;
                if(certradauditcameraserialno === null) return;
                if(certradauditsourcetype === null) return;
                if(certradauditsourcestrength === null) return;
                if(certradauditdevicemodelno === null) return;
                if(certradauditsourceserialno === null) return;
                if(certradauditsurveymetermodel === null) return;
                if(certradauditsurveymeterserialno === null) return;
                if(certradauditsurveymetercalduedate === null) return;
                if(certradauditdosimetermodel === null) return;
                if(certradauditdosimeterserialno === null) return;
                if(certradauditdosimetercalduedate === null) return;
                if(certradauditalarmratemetermodel === null) return;
                if(certradauditalarmratemeterserialno === null) return;
                if(certradauditalarmratemetercalduedate === null) return;
                if(certradauditPPEcheck === null) return;

                if(certradauditPPEothercheck === null) return;
                if(certradauditrestrictedsignagecheck === null) return;
                if(certradauditrestrictedcontrolcheck === null) return;
                if(certradaudithighradsignagecheck === null) return;
                if(certradauditmetercheck === null) return;
                if(certradauditequipmentcheck === null) return;
                if(certradauditreadingcheck === null) return;
                if(certradauditstoragecheck === null) return;
                if(certradauditstoragesignagecheck === null) return;
                if(certradauditprocedurecheck === null) return;
                if(certradauditusagecheck === null) return;
                if(certradauditrestrictedvisualcheck === null) return;
                if(certradauditnoncompliancecheck === null) return;
                if(certradauditsecuritycontrolcheck === null) return;
                if(certradauditresult === null) return;

            return {  certradauditdate, certradauditarracert,certradauditdevicemodelno,certradauditlocation,certradauditfilmbadge,certradauditcameraserialno,certradauditsourcetype,certradauditsourcestrength,certradauditsourceserialno,certradauditsurveymetermodel,certradauditsurveymeterserialno,
                certradauditsurveymetercalduedate,certradauditdosimetermodel,certradauditdosimeterserialno,certradauditdosimetercalduedate,certradauditalarmratemetermodel,certradauditalarmratemeterserialno, certradauditalarmratemetercalduedate,
                certradauditPPEcheck, certradauditPPEothercheck, certradauditrestrictedsignagecheck, certradauditrestrictedcontrolcheck, certradaudithighradsignagecheck, certradauditmetercheck, certradauditlogcheck, certradauditknowledgecheck, 
                certradauditequipmentcheck, certradauditreadingcheck, certradauditstoragecheck, certradauditstoragesignagecheck, certradauditprocedurecheck, certradauditusagecheck, certradauditrestrictedvisualcheck, certradauditnoncompliancecheck, 
                certradauditsecuritycontrolcheck, certradauditresult, certradauditnotes,certradaudittime,certradauditdonebyID,
                certradauditreviewedbyID,
                
                 ...fields  };

        }
    });

    const [certradauditdonebyID] = useMuiWatch({name: ["certradauditdonebyID"], control});
    const [certradauditreviewedbyID] = useMuiWatch({name: ["certradauditreviewedbyID"], control});
   
    console.log(window.innerWidth);


    return (
        <MuiForm
            {...props}
            {...muiFormProps}
			ref={ref}

        >
            <Alignment column>
                <Alignment row sx={{ marginBottom: '10px', marginTop: '5px'}}>
                <TextField
                    disabled
                    label={"Technician"}
                    value={employeeName}
                    />

            
                
                </Alignment>
                <FormTextField
            control={control}
            name={"certradauditlocation"}
            label={"Location"}
            sx={{flex: 1, maxWidth: '400px', marginBottom: '10px'}}
            disabled={disabled}

            />

            
            <Alignment row sx={{marginBottom: '10px'}}>
        
                <FormDatePicker
                    control={control}
                    name={"certradauditdate"}
                    label={"Date"}
                    required
                    renderInput={(params) => <TextField {...params}/>}
                    sx={{ width: '150px'}}
                    disabled={disabled}
                    />
            <FormTextField
            control={control}
            name={'certradaudittime'}
            label={'Time'}
            sx={{width: '150px', marginLeft: '5px'}}
            disabled={disabled}

            />
            </Alignment>
            
            <Alignment row sx={{ marginBottom: '10px'}}>

            <FormNumberSelect
            control={control}
            name={'certradauditarracert'}
            label={"Level"}
            options={(option) => (levelOptions ?? []).map(level => option(level.value, level.label))}
            sx={{flex: '1' ,maxWidth: '300px'}}
            disabled={disabled}

            />

            <FormAutocomplete
                control={control}
                name={"certradauditdevicemodelno"}
                loading={!deviceModelOptions}
                autoComplete
                autoHighlight
                options={deviceModelOptions?? []}
                getValue={(value, options) => options.find(x => x === value) ?? value}
                onInputChange={(option) => option}
                onChange={option => option}
                renderInput={(params) => <TextField {...params} label={"Model No"}/>}
                freeSolo
                sx={{ width: '150px', marginLeft: '5px'}}
                disabled={disabled}

            />
            </Alignment>
            <Alignment row >
            <Alignment row>
            <Typography sx={{marginTop: '3px',}}  fontSize={14} fontWeight={'bold'}>Film Badge: </Typography>
            
            <FormYesNo
            control={control}
            name={"certradauditfilmbadge"}
            sx={{ marginLeft: '10px',}}
            disabled={disabled}

            />
            </Alignment>

              </Alignment>
            

              <FormTextField
            control={control}
            name={'certradauditcameraserialno'}
            label={'Camera Serial No.'}
            disabled={disabled}
            sx={{width: '230px', marginBottom: '10px'}}
            />
            <Divider/>
            <Typography fontWeight={'bold'} fontSize={14} > Source </Typography>
            <Divider/>
            <Alignment row sx={{ marginBottom: '10px', marginTop: '5px'}}>
            <FormAutocomplete
                control={control}
                name={"certradauditsourcetype"}
                loading={!radioisotopeOptions}
                autoComplete
                autoHighlight
                options={radioisotopeOptions?? []}
                getValue={(value, options) => options.find(x => x === value) ?? null}
                onInputChange={(option) => option}
                onChange={option => option}
                renderInput={(params) => <TextField {...params} label={"Radioisotope"}/>}
                freeSolo
                sx={{ width: '230px'}}
                disabled={disabled}


            />
            <FormNumberTextField
            control={control}
            name={'certradauditsourcestrength'}
            label={'Strength'}
            decimalScale={2}
            sx={{marginLeft: '5px'}}
            disabled={disabled}

            />
            <FormTextField
            control={control}
            name={'certradauditsourceserialno'}
            label={"Serial No."}
            sx={{marginLeft: '5px'}}
            disabled={disabled}

            
            />

            </Alignment>
            <Typography fontWeight={'bold'} fontSize={14} > Survey Meter </Typography>
            <Divider/>
            <Alignment row sx={{ marginBottom: '10px', marginTop: '5px'}}>
                <FormTextField
                control={control}
                name={'certradauditsurveymetermodel'}
                label={'Model'}
                disabled={disabled}

                />
                <FormTextField
                control={control}
                name={'certradauditsurveymeterserialno'}
                label={'S/N'}
                sx={{marginLeft: '5px'}}
                disabled={disabled}

                />
                <FormDatePicker
                control={control}
                name={'certradauditsurveymetercalduedate'}
                label={"Due"}
                required
                renderInput={(params) => <TextField  {...params}/>}
                sx={{ width: '180px', marginLeft: '5px'}}
                disabled={disabled}

                />


            </Alignment>
            <Typography fontWeight={'bold'} fontSize={14}>Pocket Dosimeter </Typography>
            <Divider/>
            <Alignment row sx={{ marginBottom: '10px', marginTop: '5px'}}>
                <FormTextField
                control={control}
                name={'certradauditdosimetermodel'}
                label={'Model'}
                disabled={disabled}

                />
                <FormTextField
                control={control}
                name={'certradauditdosimeterserialno'}
                label={'S/N'}
                sx={{marginLeft: '5px'}}
                disabled={disabled}

                />
                <FormDatePicker
                control={control}
                name={'certradauditdosimetercalduedate'}
                label={"Due"}
                required
                renderInput={(params) => <TextField {...params}/>}
                sx={{ width: '180px', marginLeft: '5px'}}
                disabled={disabled}

                />


            </Alignment>
            <Typography fontSize={14} fontWeight={'bold'}>Alarm Rate</Typography>
            <Divider/>
            <Alignment row sx={{ marginBottom: '10px', marginTop: '5px'}}>
                <FormTextField
                control={control}
                name={'certradauditalarmratemetermodel'}
                label={'Model'}
                disabled={disabled}

                />
                <FormTextField
                control={control}
                name={'certradauditalarmratemeterserialno'}
                label={'S/N'}
                sx={{marginLeft: '5px'}}
                disabled={disabled}

                />
                <FormDatePicker
                control={control}
                name={'certradauditalarmratemetercalduedate'}
                label={"Due"}
                required
                renderInput={(params) => <TextField {...params}/>}
                sx={{ width: '180px', marginLeft: '5px'}}
                disabled={disabled}

                />


            </Alignment>

            <Divider/>
            <Typography fontWeight="bold" fontSize={14} sx={{marginTop: '10px'}}>Check List</Typography>

<Divider />
<Box
  sx={{
    display: 'grid',
    gridTemplateColumns: window.innerWidth < 400 ? '35px .8fr 100px' : '35px 1fr 100px', // When screen width is less than the 'sm' breakpoint (~600px by default)


    
    gap: '10px',
    width: window.innerWidth < 1000 ? `${window.innerWidth}px` : '1000px',
    overflowY: 'auto', // Scrollable if content exceeds maxHeight
  }}
>
  {/* Header Row */}
  <Typography  fontSize={14} fontWeight="bold" align="center">No.</Typography>
  <Typography  fontSize={14} fontWeight="bold">Question</Typography>
  <Typography  fontSize={14} fontWeight="bold"></Typography>

      {/* Rows for Questions */}
      {[
        {
          no: '1.',
          question: 'Are the radiographers and assistants wearing their film badge, personnel dosimeter, and alarming ratemeter?',
          name: 'certradauditPPEcheck',
        },
        {
          no: '2.',
          question: 'Are other individuals working within the restricted area wearing personnel monitoring equipment required in number 1 above?',
          name: 'certradauditPPEothercheck',
        },
        {
          no: '3.',
          question: 'Is the restricted area posted with sufficient “CAUTION (or DANGER) RADIATION AREA” signs?',
          name: 'certradauditrestrictedsignagecheck',
        },
        {
          no: '4.',
          question: 'Is the restricted area properly controlled to prevent unauthorized entry?',
          name: 'certradauditrestrictedcontrolcheck',
        },
        {
          no: '5.',
          question: 'Is the high-radiation area posted with sufficient “CAUTION (or DANGER) HIGH RADIATION AREA” signs?',
          name: 'certradaudithighradsignagecheck',
        },
        {
          no: '6.',
          question: 'Are there at least 2 calibrated and properly operating survey meters?',
          name: 'certradauditmetercheck',
        },
        {
          no: '7.',
          question: 'Is the utilization log properly filled out?',
          name: 'certradauditlogcheck',
        },
        {
          no: '8.',
          question: 'Do the radiographer/radiographer assistant have sufficient knowledge of safety rules? (Ascertained by oral questions)',
          name: 'certradauditknowledgecheck',
        },
        {
          no: '9.',
          question: 'Is the radiographic equipment defective?',
          name: 'certradauditequipmentcheck',
        },
        {
          no: '10.',
          question: 'Do the radiographer/radiographer assistant survey the source projector and source tube. Do they take a radiation reading one foot in front of the source following the radiographic exposure?',
          name: 'certradauditreadingcheck',
        },
        {
          no: '11.',
          question: 'Are radiation sources stored properly and kept locked to prevent unauthorized removal?',
          name: 'certradauditstoragecheck',
        },
        {
          no: '12.',
          question: 'Was the storage area posted with “CAUTION (or DANGER) RADIOACTIVE MATERIAL” signs?',
          name: 'certradauditstoragesignagecheck',
        },
        {
          no: '13.',
          question: 'Is there a copy of the operating and emergency procedures and license readily available?',
          name: 'certradauditprocedurecheck',
        },
        {
          no: '14.',
          question: 'Do the radiographer and/or assistant know how to use a survey meter and make an accurate reading of the dose rates?',
          name: 'certradauditusagecheck',
        },
        {
          no: '15.',
          question: 'Is proper visual surveillance of the restricted area maintained?',
          name: 'certradauditrestrictedvisualcheck',
        },
        {
          no: '16.',
          question: 'Are there any items of noncompliance other than those listed on this form? (If any, explain in remarks.)',
          name: 'certradauditnoncompliancecheck',
        },
        {
          no: '17.',
          question: 'Security Controls appear to be adequate? (as described in procedures, including verification that truck key fob is charged and alarm is fully operable.)',
          name: 'certradauditsecuritycontrolcheck',
        },
      ].map((item, index) => (
        <React.Fragment key={index}>
          <Typography fontWeight="bold"  fontSize={14} align="center">
            {item.no}
          </Typography>
          <Typography fontWeight="bold"  fontSize={14}>{item.question}</Typography>
          <FormYesNoSwitch
          labelPlacement={window.innerWidth < 1000 ? 'bottom' : 'end'}
          disabled={disabled}

      control={control}
      name={item.name as   "certradauditPPEcheck" | "certradauditPPEothercheck" | "certradauditrestrictedsignagecheck" | "certradauditmetercheck"
        |"certradauditlogcheck" | "certradauditknowledgecheck" | "certradauditequipmentcheck" | "certradauditreadingcheck" | "certradauditstoragecheck" | "certradauditstoragesignagecheck" | 
        "certradauditprocedurecheck" | "certradauditusagecheck" | "certradauditrestrictedvisualcheck" | "certradauditnoncompliancecheck" | "certradauditsecuritycontrolcheck" | "certradauditrestrictedcontrolcheck" | "certradaudithighradsignagecheck"}
      sx={{ marginLeft: '5px' }}
    />

        </React.Fragment>
      ))}
    </Box>
    <Divider sx={{marginTop: '5px', marginBottom: '5px'}}/>

    <FormNumberSelect
            control={control}
            name={'certradauditresult'}
            label={"Performance"}
            options={(option) => (resultOptions ?? []).map(result => option(result.value, result.label))}
            sx={{flex: '1', marginTop: '5px', marginBottom: '10px', width: '200px'}}
            disabled={disabled}

            />  
            <FormTextField
            control={control}
            name={'certradauditnotes'}
            label={"Notes"}
            multiline
            rows={3}
            disabled={disabled}
            sx={{maxWidth: '600px'}}

            /> 


           
           
            </Alignment>
			



        </MuiForm>
    );
}));