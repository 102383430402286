import React from 'react';
import { FieldValues, FieldPath } from 'react-hook-form';
import {
  Switch,
  SwitchProps,
  FormControlLabel,
  FormControlLabelProps,
  Skeleton,
  SxProps,
  Theme,
  FormHelperText,
  Box,
} from '@mui/material';
import { FormInputProps } from './types';
import { useMuiFormController } from './utils';

export interface FormYesNoSwitchProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends FormInputProps<boolean | null, TFieldValues, TName>,
    Pick<SwitchProps, 'color' | 'size' | 'disabled' | 'tabIndex'>,
    Pick<FormControlLabelProps, 'labelPlacement' | 'sx' | 'style' | 'className'> {
  yesLabel?: string;
  noLabel?: string;
  switchSx?: SxProps<Theme>;
}

const FormYesNoSwitch = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  loading,
  required,
  noErrorText,
  dependents,
  onBlur,
  yesLabel = 'Yes',
  noLabel = 'No',
  labelPlacement = 'end',
  sx,
  style,
  className,
  switchSx,
  ...switchProps
}: FormYesNoSwitchProps<TFieldValues, TName>) => {
  const {
    value,
    onChange,
    handleBlur,
    ref,
    isLoading,
    isViewOnly,
    hasError,
    helperText,
  } = useMuiFormController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    loading,
    required,
    noErrorText,
    dependents,
    onBlur,
  });

  // Render loading state
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton
          variant="rectangular"
          sx={{
            minHeight: '20px',
            height: '20px',
            width: '100px',
            borderRadius: '2px',
            ...switchSx,
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', ...sx }} style={style} className={className}>
        <FormControlLabel
          control={
            <Switch
              {...switchProps}
              disabled={isViewOnly || switchProps.disabled}
              ref={ref}
              checked={value === true}
              onChange={(event) => {
                const checked = event.target.checked;
                onChange(checked);
                handleBlur(checked);
              }}
              sx={{ marginRight: '8px', ...switchSx }}
            />
          }
          label={value ? yesLabel : noLabel}
          labelPlacement={labelPlacement}
        />
      </Box>
      {helperText ? <FormHelperText error={hasError}>{helperText}</FormHelperText> : null}
    </>
  );
};

export { FormYesNoSwitch };
