import { Divider, IconButton, MenuItem, Paper, TextField, Typography, Autocomplete } from '@mui/material';
import { memo, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { Alignment, LabeledItem, Spacer } from '@imas/components/layout';
import { NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import { dailyScheduleViewerStyles } from './DailyScheduleViewerStyles';
import { DatePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { DailyScheduleDialog } from './DailyScheduleDialog/DailyScheduleDialog';
import { useSelector } from 'react-redux';
import { getEmployeeData } from '@imas/redux';
import { useNavigate, useParams } from 'react-router';
import { useLoadApi } from '@imas/api';
import { CheckIn } from './CheckIn/CheckIn';
import { DailyScheduleViewerContext } from './DailyScheduleViewerContext';
import { getTotalWorkedTime, getWorkedTime, getCheckInTime } from './DailyScheduleViewerUtils';
import { DailyScheduleEntryList } from './DailyScheduleEntryList';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce/lib';
import { GetOnCallSchedule, GetDailyScheduledEvents } from 'src/api/schedule';
import { ScheduleType, ScheduleEventEntry } from 'src/api/types/api/schedule/schedule';
import { EmployeeCheckedInStatus, EmployeeCheckInHistory } from 'src/api/employee/api';
import { GetCurrentWorkTimes } from '@imas/api/employee/work-times';
import moment, { Moment } from 'moment';
import AutoSizer from 'react-virtualized-auto-sizer';
import Enumerable from 'linq';
import Fuse from 'fuse.js';
import axios from 'axios';

export interface SelectedEvent {
    id: number,
    itemType: number,
    date: Moment
}

export const DailyScheduleViewer = memo(() => {
    const { classes } = dailyScheduleViewerStyles();

    //use navigate
    const navigate = useNavigate();

    //use params & search params
    const [searchParams] = useSearchParams();

    //get react router params to get the current schedule state
    const { state: scheduleState } = useParams();
  
    //convert search params into a memoized object representing the params
    const selectedEvent = useMemo(() => {
        const params = {
            id: searchParams.get("id"),
            type: searchParams.get("type"),
            date: searchParams.get("date")
        };
        
        //check each param to see if it was provided & if it is a valid type (valid number/date etc...)
        if (params.id === null || isNaN(Number(params.id))) return null;
        if (params.type === null || isNaN(Number(params.type))) return null;
        if (params.date === null || !moment(params.date, "M-D-yyyy").isValid()) return null;

        return {
            id: Number(params.id),
            itemType: Number(params.type),
            date: moment(params.date, "M-D-yyyy")
        } as SelectedEvent;
    }, [searchParams]);

	//convert just the id param for checking-into-job use
	const eventID = useMemo(() => {
		const param = searchParams.get("id");
		return Number(param);
	}, [searchParams]);

    //get the data of the logged in employee
    const employeeData = useSelector(getEmployeeData);
    
    /**
     *  Component State Variables:
     *      selectedDay - A moment.js object representing the current day
     *      selectedScheduleType - Used to select the type of records to return from the API. Valid values are "me", "field", "lab", "all" 
	 * 		selectedScheduleViewType - Used to filter down the list of current records to filter short- and long-term scheduled events. Valid values are "all", "short", "long"
     *      filter - A search filter used to filter down the list of current records to make specific records easier to find.
     */
    const [selectedDay, setSelectedDay] = useState<Moment>(moment());
	const [selectedScheduleType, setSelectedScheduleType] = useState<ScheduleType>(ScheduleType.ME);
	const [selectedScheduleViewType, setSelectedScheduleViewType] = useState<"short" | "long" | "all">("all");
    const [filter, setFilter] = useState<string | null>(null);

    //debounce the setFilter function
    const setFilterDebounced = useDebouncedCallback((value: string | null) => setFilter(value), 200);

    //refs to selectedDay
    const selectedDayRef = useRef(selectedDay);

    //ref to schedule list element
    const scheduleListRef = useRef<HTMLUListElement>(null);

    //get schedule events
    const {
        data: scheduledEvents 
    } = useLoadApi(GetDailyScheduledEvents, 
        [
            selectedScheduleType === ScheduleType.ME ? employeeData?.employeeId ?? -1 : null, 
            selectedDay, 
            employeeData?.sitenum ?? null, 
            ({ ME: null, FIELD: 0, LAB: 1, ALL: null }[selectedScheduleType])
        ], [selectedScheduleType, selectedDay, employeeData]);

    //get the on call schedule
    const { data: onCallSchedule } = useLoadApi(GetOnCallSchedule, [selectedDay], [selectedDay]);

    //get last check in event
    const { data: lastEvent, call: refreshLastEvent } = useLoadApi(EmployeeCheckedInStatus, [], []);

    //get check in event history
    const { data: checkInHistory, call: refreshCheckInHistory } = useLoadApi(EmployeeCheckInHistory, [selectedDay.clone().subtract(1, "day").startOf("day"), selectedDay.clone().add(1, "day").endOf("day")], [selectedDay]);

    //update refs when values change
    useEffect(() => { selectedDayRef.current = selectedDay; }, [selectedDay]);

    //callback for refreshing check in history
    const refreshEvents = useCallback(() => {
        refreshLastEvent();
        refreshCheckInHistory();
    }, [refreshLastEvent, refreshCheckInHistory]);

    //callback to get hours worked for a specific event entry (returns 0 if not within current or last day)
    const getHours = useCallback((entry: ScheduleEventEntry | null) => {
        return getWorkedTime(entry, checkInHistory ?? [], selectedDayRef.current);
    }, [checkInHistory]);

    //callback to get total hours worked (returns 0 if not within current or last day)
    const totalHoursWorked = useMemo(() => {
        return getTotalWorkedTime(checkInHistory ?? [], selectedDay);
    }, [checkInHistory, selectedDay]);

    const startTime = useMemo(() => {
        return getCheckInTime(checkInHistory ?? [], selectedDay);
    }, [checkInHistory, selectedDay]);

    //handle when a user clicks on a given schedule item
    const handleScheduleItemClick = useCallback((event: ScheduleEventEntry) => {
        //navigate to open the dialog
        navigate(axios.getUri({ url: "/dashboard/schedule/event", params: { id: event.id, type: event.itemTypeId, date: moment(event.scheduleDate).format("M-D-yyyy") }}));
    }, []);

    //get value of general hours
    const generalHours = getHours(null);

    //get filtered & sorted events
    const filteredScheduleEvents = useMemo((): ScheduleEventEntry[] | undefined => {
        if (!scheduledEvents) return scheduledEvents;
			
		const shortLongFilteredEvents = selectedScheduleViewType === "all" ? scheduledEvents
			: selectedScheduleViewType === "short" ? scheduledEvents.filter(x => !x.solongterm)
			: scheduledEvents.filter(x => x.solongterm);

        //filter the records if a filter is provided
        if (filter !== null) {
            //crease a fuse object for the list of schedule entries
			const fuse = new Fuse<ScheduleEventEntry & { searchText: string }>(shortLongFilteredEvents.map(x => ({ ...x, searchText: x.employeeName + " " + x.fullJobName + " " + x.client })), {
                useExtendedSearch: true,
                includeMatches: true,
                includeScore: true,
                keys: ["employeeName", "fullJobName", "client"], //["searchText"],
            });

            //set the list to the top when the filter is changed
            if (scheduleListRef.current) scheduleListRef.current.scrollTop = 0;

            return fuse.search(`'"${filter}"`).map(x => x.item);
        }
        else {
			return Enumerable.from(shortLongFilteredEvents).orderBy(x => x.itemTypeId === 2).thenBy(x => x.employeeNameShort).thenBy(x => (x.labTime === 'am/pm') ? '99999' : x.labTime).toArray();
        };
	}, [scheduledEvents, filter, selectedScheduleViewType]);

    return (
        <DailyScheduleViewerContext.Provider value={{
			onCallSchedule,
            lastEvent,
            checkInHistory: checkInHistory ?? [],
            selectedDay,
            totalHoursWorked,
            refreshEvents,
            getHours,
        }}>
            <Paper className={classes.viewerContainer}>
                {/* Component title / date picker */}
                <Alignment row className={classes.scheduleNavigationContainer}>
                    {/* Go back 1 day button */}
                    <IconButton
                        className={classes.navigationButton}
                        onClick={() => { setSelectedDay(moment(selectedDay.add(-1, 'days').format())); }}
                        size={"medium"}
                    ><NavigateBeforeIcon/></IconButton>

                    {/* Title & Date Picker container */}
                    <Alignment column className={classes.titleDateContainer}>
                        {/* Title */}
                        <Typography variant={"h5"} className={classes.title}>{"Schedule"}</Typography>
                        <Spacer vertical size={"5px"}/>
                        
                        {/* Date / Record Type Selector */}
                        <Alignment row>
                            {/* Date Picker */}
                            <DatePicker
                                value={selectedDay}
                                onChange={(val) => { if (val && val.isValid()) setSelectedDay(val); }}
                                renderInput={(props) => <TextField {...props} label={"Date"} variant={"outlined"} size={"small"} helperText={null}/>}
                            />
                            <Spacer horizontal size={"10px"}/>
                        
                            {/* Record Type Selector */}
                            <TextField
                                label={"Type"}
                                variant={"outlined"}
                                size={"small"}
                                select
                                value={selectedScheduleType}
                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => setSelectedScheduleType(event.target.value as ScheduleType)}
                                className={classes.recordTypeSelector}
                            >
                                <MenuItem value={"ME"}>{"Me"}</MenuItem>
                                <MenuItem value={"FIELD"}>{"Field"}</MenuItem>
                                <MenuItem value={"LAB"}>{"Lab"}</MenuItem>
                                <MenuItem value={"ALL"}>{"All"}</MenuItem>
                            </TextField>
                        </Alignment>
                        <Spacer vertical size={'15px'}/>

                        <Alignment row>
							{/* Filter Text Field */}
							<Autocomplete
								freeSolo
								size={"small"}
								options={[]}
								fullWidth
								onInputChange={(_, value) => setFilterDebounced(value === "" ? null : value)}
								renderInput={(params) => <TextField {...params} label={"Filter"} variant={"outlined"} size={"small"} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}/>}
							/>
                            <Spacer horizontal size={"10px"}/>
                        
                            {/* Record Type Selector */}
                            <TextField
                                label={"View"}
                                variant={"outlined"}
                                size={"small"}
                                select
                                value={selectedScheduleViewType}
                                onChange={(event: React.ChangeEvent<{ value: unknown }>) => setSelectedScheduleViewType((event.target.value as "all" | "short" | "long"))}
                                className={classes.recordTypeSelector}
                            >
                                <MenuItem value={"all"}>{"All"}</MenuItem>
                                <MenuItem value={"short"}>{"Short"}</MenuItem>
                                <MenuItem value={"long"}>{"Long"}</MenuItem>
                            </TextField>
						</Alignment>
                    </Alignment>

                    {/* Go forward 1 day button */}
                    <IconButton
                        className={classes.navigationButton}
                        onClick={() => { setSelectedDay(moment(selectedDay.add(1, 'days').format())); }}
                        size={"medium"}
                    ><NavigateNextIcon/></IconButton>
                </Alignment>

				{/* Options & Events Divider */}
                <Divider/>

				{/* Schedule Event List (Includes On-Call Schedule) */}
				<Alignment column flex>
					<AutoSizer>
						{({ width, height }) => (
							<DailyScheduleEntryList
								width={width}	
								height={height}
								events={filteredScheduleEvents}
								type={selectedScheduleType} 
								checkedInId={lastEvent?.workId} 
								onClick={handleScheduleItemClick}
							/>
						)}
					</AutoSizer>
				</Alignment>

                {selectedScheduleType === ScheduleType.ME && (generalHours !== 0 || totalHoursWorked !== 0) ? (
                    <>
                        <Divider/> 
                                <Alignment column className={classes.hoursContainer}>

                                    {startTime !== undefined && lastEvent?.typeId !== undefined? (
                                                <LabeledItem 
                                    label={"Check-in:"}
                                    labelProps={{variant: "subtitle2"}}
                                    item={startTime}
                                    itemTextProps={{variant: "subtitle2"}}
                                    row
                                />
                                            ) : null}
                                    {/* General Clock In Time List Item */}
                                    {generalHours !== 0 ? (
                                <LabeledItem 
                                    label={"General Hours:"}
                                    labelProps={{variant: "subtitle1"}}
                                    item={generalHours.toFixed(2)}
                                    itemTextProps={{variant: "subtitle1"}}
                                    row
                                />
                            ) : null}

                            {/* Total Clock In Time List Item */}
                            {totalHoursWorked !== 0 ? (
                                <LabeledItem 
                                    label={"Total Hours Worked:"}
                                    labelProps={{variant: "h6"}}
                                    item={totalHoursWorked.toFixed(2)}
                                    itemTextProps={{variant: "h6"}}
                                    row
                                />
                            ) : null}
                            
                        </Alignment>
                        <Spacer vertical/>
                    </>
                ) : <>
                                        <Divider/> 
                                        <Alignment column className={classes.hoursContainer}>
                                        {startTime !== undefined && lastEvent?.typeId !== undefined ? (
                                                <LabeledItem 
                                    label={"Check-in:"}
                                    labelProps={{variant: "subtitle2"}}
                                    item={startTime}
                                    itemTextProps={{variant: "subtitle2"}}
                                    row
                                />
                                            ) : null}
                                        </Alignment>



                </>}

                {/* Clock In / Out (only show if 'me' is the selected schedule type) */}
                {scheduleState === "checking-into-job" ? (
                    <CheckIn
						entries={scheduledEvents?.filter(x => x.id === (eventID ?? -1) ) ?? []}
						setType={setSelectedScheduleType}
						setDay={setSelectedDay}
                    />
                ) : selectedScheduleType === ScheduleType.ME ? (
                    <CheckIn
						entries={scheduledEvents ?? []}
						setType={setSelectedScheduleType}
						setDay={setSelectedDay}
                    />					
				) : null}

                {/* extra DailyScheduleDialog popup when a DailyScheduleEntry list item is clicked on */}
                <DailyScheduleDialog
                    event={selectedEvent}
                    onClose={() => navigate("/dashboard")}
                />
            </Paper>
        </DailyScheduleViewerContext.Provider>
    );
});