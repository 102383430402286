import React from 'react';
import { FieldValues, FieldPath } from 'react-hook-form';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  Skeleton,
  SxProps,
  Theme,
  FormHelperText,
  Box,
} from '@mui/material';
import { FormInputProps, OnBlur } from './types';
import { useMuiFormController } from './utils';

export interface FormYesNoProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends FormInputProps<boolean | null, TFieldValues, TName>,
    Pick<CheckboxProps, 'color' | 'size' | 'icon' | 'checkedIcon' | 'disabled' | 'tabIndex'>,
    Pick<FormControlLabelProps, 'labelPlacement' | 'sx' | 'style' | 'className'> {
  yesLabel?: string;
  noLabel?: string;
  labelSize?: string;
  checkboxSx?: SxProps<Theme>;
}

const FormYesNo = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  loading,
  required,
  noErrorText,
  dependents,
  onBlur,
  yesLabel = 'Yes',
  noLabel = 'No',
  labelSize = '14px',
  labelPlacement,
  sx,
  style,
  className,
  checkboxSx,
  ...checkboxProps
}: FormYesNoProps<TFieldValues, TName>) => {
  const {
    value,
    onChange,
    handleBlur,
    ref,
    isLoading,
    isViewOnly,
    hasError,
    helperText,
  } = useMuiFormController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
    loading,
    required,
    noErrorText,
    dependents,
    onBlur,
  });

  // Render loading state
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton
          variant="rectangular"
          sx={{
            minHeight: '20px',
            height: '20px',
            width: '60px',
            marginRight: '10px',
            borderRadius: '2px',
            ...checkboxSx,
          }}
        />
        <Skeleton
          variant="rectangular"
          sx={{
            minHeight: '20px',
            height: '20px',
            width: '60px',
            borderRadius: '2px',
            ...checkboxSx,
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', ...sx }} style={style} className={className}>
        <FormControlLabel
          label={yesLabel}
          labelPlacement={labelPlacement}
          sx={{fontSize: labelSize }}
          control={
            <Checkbox
              {...checkboxProps}
              disabled={isViewOnly || checkboxProps.disabled}
              ref={ref}
              checked={value === true}
              onChange={() => {
                onChange(true);
                handleBlur(true);
              }}
              sx={{ padding: '3px', marginRight: '4px', ...checkboxSx }}
            />
          }
        />
        <FormControlLabel
          label={noLabel}
          labelPlacement={labelPlacement}
          control={
            <Checkbox
              {...checkboxProps}
              disabled={isViewOnly || checkboxProps.disabled}
              checked={value === false}
              onChange={() => {
                onChange(false);
                handleBlur(false);
              }}
              sx={{ padding: '3px', marginRight: '4px', ...checkboxSx }}
            />
          }
        />
      </Box>
      {helperText ? <FormHelperText error={hasError}>{helperText}</FormHelperText> : null}
    </>
  );
};

export { FormYesNo };
