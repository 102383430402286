import { useMemo } from 'react';
import { GetvInspectionsForVehicle, GetvOpenVehicleInspections } from 'src/api/misc/vehicles';
import { getAllEmployeeCert } from '../employee';
import { useHub, useOnHub } from '../_hubs';
import { UseLoadApiOptionsFromApi, useLoadApi } from '../_internals';
import { createUpdateHandler, DataHookResult } from './_utils';

//get all vehicle inspections with outstanding issues
export const useOpenVehicleInspections = (options?: UseLoadApiOptionsFromApi<typeof GetvOpenVehicleInspections>) => {
	//load data
    const api = useLoadApi(GetvOpenVehicleInspections, [], [], options);

	//use signalR hub
	const hub = useHub("/vehicles/inspections");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		
		if (type === 'UPDATE'){
			if(record.inspectionCompleted ){
				//remove the record from the uncompleted list, because it is complete
				update('DELETE', record);
			}
			else{
				//update the list
				update(type, record);
			}

		}
		else if (type === 'CREATE' && record.inspectionCompleted){
			//update the list
			update(type, record);
		};


	}, []);
	
	return [api.data, api] as DataHookResult<typeof api>;
};

//get inspections for a specific vehicle, by default 
export const useVInspectionsforVehicle = (calItemId: int, lastYear: boolean, options?: UseLoadApiOptionsFromApi<typeof GetvInspectionsForVehicle>) => {
	//load data
    const api = useLoadApi(GetvInspectionsForVehicle, [calItemId, lastYear], [calItemId, lastYear], options);

	//use signalR hub
	const hub = useHub("/vehicles/inspections");

	//update handler
	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);

	//listen for updates and update the list of data
	useOnHub(hub, "Update", (type, record) => {
		//ignore records which dont belong to the vehicle
		if (record.calItemId !== calItemId) return;

		//ignore records that have no issues if lastYear==false
		if (!lastYear && !record.inspectionWithIssue) return;

		//update the list
		update(type, record);
	}, [calItemId]);
	
	return [api.data, api] as DataHookResult<typeof api>;
};


export const useAllEmployeeCert = (options?: UseLoadApiOptionsFromApi<typeof getAllEmployeeCert>) => {
	//load data
	const api = useLoadApi(getAllEmployeeCert, [], [], options);
	//use signalR hub
	const hub = useHub('/employees/certification');

	const update = useMemo(() => createUpdateHandler(api.update), [api.update]);
	useOnHub(hub, "Update", (type, record) => {
		//update the list
		update(type, record);
	}, []);
	return [api.data , api] as DataHookResult<typeof api>;

};


